import {
  abortSmsVerification,
  clearOrderResponse,
  initCheckout,
  setCashierPayment,
  setCashierPaymentDone,
  setCashierToken,
  setCheckoutStep,
  setContact,
  setContactField,
  setContactId,
  setContactlessDelivery,
  setDeliveryAddress,
  setDeliveryAddressComment,
  setDeliveryAddressLine2,
  setCurbside,
  setDispatch,
  setDispatchTime,
  setDispatchType,
  setErrorType,
  setMembersAPiContact,
  setPayment,
  setPendingCashierPayment,
  setSmsError,
  setSmsLoading,
  setTip,
  submitOrder,
  submitOrderSuccess,
  submitSmsCode,
  setMemberContactLoading,
  setCurbsideOutfitInfo,
  setSelectedAddressId,
  setDineInTable,
  setLoyaltyPointsToRedeem,
  resetLoyaltyPointsToDefaultValue,
  setDeliveryAddressLabel,
} from './checkout.actions';
import { createReducer } from '../common/reducerUtils';
import { EMPTY_ADDRESS } from '../../core/constants';
import {
  SetCashierTokenPayload,
  SetCheckoutStepPayload,
  SetContactFieldPayload,
  SetContactIdPayload,
  SetContactlessDeliveryPayload,
  SetContactPayload,
  SetCurbsidePayload,
  SetCurbsideOutfitInfoPayload,
  SetDeliveryAddressPayload,
  SetDispatchPayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
  SetErrorTypePayload,
  SetMemberContactLoadingPayload,
  SetMembersAPiContactPayload,
  SetPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetSelectedAddressIdPayload,
  SetSmsLoadingPayload,
  SetTipPayload,
  SubmitOrderSuccessPayload,
  SetDineInTablePayload,
  SetLoyaltyPointsToRedeemPayload,
  SetDeliveryAddressLine2Payload,
  SetDeliveryAddressCommentPayload,
  SetDeliveryAddressLabelPayload,
} from './checkout.actions.types';
import {
  ActionHandlers,
  CashierPayment,
  Contact,
  Dispatch,
  Order,
  OrderFailureType,
  Payment,
  Tip,
} from '@wix/restaurants-client-logic';
import { CheckoutStep } from '../../core/types/Checkout';
import { Contact as MembersApiContact } from '@wix/ambassador-members-ng-api/http';
import { DeliveryDispatch, PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { getDispatchTypeFromVirtual } from '../../core/logic/dispatchLogic';

interface CheckoutState {
  contact: Contact;
  membersApiContact: MembersApiContact;
  dispatch: Dispatch;
  payments: Payment[];
  pendingCashierPayment: Partial<CashierPayment>;
  orderResponse?: Order;
  isContactlessDeliveryChecked?: boolean;
  cashierToken?: string;
  tip?: Tip;
  checkoutStep: CheckoutStep;
  isLoading?: boolean;
  hasSmsError?: boolean;
  isSmsLoading?: boolean;
  errorType?: OrderFailureType;
  errorCode?: number;
  errorChargeId?: string;
  overrideDispatchType?: boolean;
  isMemberContactLoading: boolean;
  selectedAddressId?: string;
  loyaltyPointsToRedeem?: number;
  defaultLoyaltyPointsToRedeem?: number;
}

const getInitialState = (state?: CheckoutState): CheckoutState => {
  const dispatchType = state?.dispatch?.type;
  let dispatch;
  switch (dispatchType) {
    case 'takeout':
      dispatch = { type: 'takeout' } as PickupDispatch;
      break;
    case 'delivery':
    default:
      dispatch = { type: 'delivery', address: EMPTY_ADDRESS } as DeliveryDispatch;
      break;
  }

  return {
    membersApiContact: {},
    contact: { firstName: '', lastName: '', phone: '', email: '' },
    dispatch,
    payments: [],
    pendingCashierPayment: {},
    checkoutStep: 'address-information',
    isMemberContactLoading: false,
    selectedAddressId: undefined,
  };
};

const handlers: ActionHandlers<CheckoutState> = {
  [setContact.toString()]: (state, payload: SetContactPayload) => {
    return {
      ...state,
      contact: payload.contact,
    };
  },
  [setContactId.toString()]: (state, payload: SetContactIdPayload) => {
    return {
      ...state,
      contact: {
        ...state.contact,
        wixContactId: payload.wixContactId,
      },
    };
  },
  [initCheckout.toString()]: (state) => {
    return getInitialState(state);
  },
  [setDispatchType.toString()]: (state, payload: SetDispatchTypePayload) => {
    const dispatchType = getDispatchTypeFromVirtual(payload.dispatchType);
    const currentDineIn = (state.dispatch as PickupDispatch).contactlessDineIn || {};
    const dispatch = {
      ...state.dispatch,
      type: dispatchType,
      contactlessDineIn: payload.dispatchType === 'dine-in' ? currentDineIn : undefined,
    } as Dispatch;

    if (dispatchType !== 'takeout') {
      delete (dispatch as PickupDispatch).isCurbside;
    }

    return {
      ...state,
      dispatch,
    };
  },
  [setDispatch.toString()]: (state, payload: SetDispatchPayload) => {
    return {
      ...state,
      dispatch: payload.dispatch,
      overrideDispatchType: true,
    };
  },
  [setTip.toString()]: (state, { tip }: SetTipPayload) => {
    return {
      ...state,
      tip,
    };
  },
  [setCheckoutStep.toString()]: (state, { step }: SetCheckoutStepPayload) => {
    return {
      ...state,
      checkoutStep: step,
      isLoading: false,
    };
  },
  [setDispatchTime.toString()]: (state, payload: SetDispatchTimePayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        time: payload.timestamp,
      },
    };
  },
  [setDeliveryAddress.toString()]: (state, payload: SetDeliveryAddressPayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        address: payload.address,
      },
    };
  },
  [setDeliveryAddressLine2.toString()]: (state, payload: SetDeliveryAddressLine2Payload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        address: {
          ...(state.dispatch as DeliveryDispatch).address,
          addressLine2: payload.addressLine2,
        },
      },
    };
  },
  [setDeliveryAddressLabel.toString()]: (state, payload: SetDeliveryAddressLabelPayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        address: {
          ...(state.dispatch as DeliveryDispatch).address,
          label: payload.label,
        },
      },
    };
  },
  [setDeliveryAddressComment.toString()]: (state, payload: SetDeliveryAddressCommentPayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        address: {
          ...(state.dispatch as DeliveryDispatch).address,
          comment: payload.comment,
        },
      },
    };
  },

  [setDineInTable.toString()]: (state, payload: SetDineInTablePayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        contactlessDineIn: {
          label: '',
          labelValue: payload.tableNumber,
        },
      },
    };
  },

  [setCurbside.toString()]: (state, payload: SetCurbsidePayload) => {
    if (state.dispatch.type !== 'takeout') {
      return state;
    }

    const dispatch: PickupDispatch = {
      ...state.dispatch,
      isCurbside: payload.isCurbside,
    };

    return {
      ...state,
      dispatch,
    };
  },
  [setCurbsideOutfitInfo.toString()]: (state, payload: SetCurbsideOutfitInfoPayload) => {
    if (state.dispatch.type !== 'takeout') {
      return state;
    }

    const dispatch: PickupDispatch = {
      ...state.dispatch,
      curbsideAdditionalInformation: payload.curbsideOutfitInfo,
    };

    return {
      ...state,
      dispatch,
    };
  },
  [setContactField.toString()]: (state, payload: SetContactFieldPayload) => {
    return {
      ...state,
      contact: {
        ...state.contact,
        [payload.field]: payload.value,
      },
    };
  },
  [setPayment.toString()]: (state, payload: SetPaymentPayload) => {
    return {
      ...state,
      payments: [payload.payment],
      isLoading: false,
    };
  },
  [setPendingCashierPayment.toString()]: (state, payload: SetPendingCashierPaymentPayload) => {
    return {
      ...state,
      pendingCashierPayment: {
        ...state.pendingCashierPayment,
        ...payload.payment,
        paymentMethodTitle: payload.paymentMethodTitle,
      },
    };
  },
  [submitOrder.toString()]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [submitOrderSuccess.toString()]: (state, { orderResponse }: SubmitOrderSuccessPayload) => {
    return {
      ...state,
      orderResponse,
      isLoading: false,
    };
  },
  [setContactlessDelivery.toString()]: (state, { isContactless }: SetContactlessDeliveryPayload) => {
    return {
      ...state,
      isContactlessDeliveryChecked: isContactless,
    };
  },
  [setCashierToken.toString()]: (state, { token }: SetCashierTokenPayload) => {
    return {
      ...state,
      cashierToken: token,
    };
  },
  [setCashierPayment.toString()]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [setCashierPaymentDone.toString()]: (state) => {
    return {
      ...state,
      cashierToken: undefined,
      isLoading: false,
    };
  },
  [clearOrderResponse.toString()]: (state) => {
    return {
      ...state,
      orderResponse: undefined,
    };
  },
  [submitSmsCode.toString()]: (state) => {
    return {
      ...state,
      hasSmsError: false,
    };
  },
  [setSmsError.toString()]: (state, payload) => {
    return {
      ...state,
      hasSmsError: payload.hasSmsError,
    };
  },
  [setSmsLoading.toString()]: (state, payload: SetSmsLoadingPayload) => {
    return {
      ...state,
      isSmsLoading: payload.loading,
    };
  },
  [setErrorType.toString()]: (state, payload: SetErrorTypePayload) => {
    return {
      ...state,
      errorType: payload.errorType,
      errorCode: payload.errorCode,
      errorChargeId: payload.errorChargeId,
      isLoading: false,
    };
  },
  [abortSmsVerification.toString()]: (state) => {
    return {
      ...state,
      isLoading: false,
      isSmsLoading: false,
      hasSmsError: false,
    };
  },
  [setMembersAPiContact.toString()]: (state, payload: SetMembersAPiContactPayload) => {
    return {
      ...state,
      membersApiContact: payload.contact,
    };
  },
  [setMemberContactLoading.toString()]: (state, payload: SetMemberContactLoadingPayload) => {
    return {
      ...state,
      isMemberContactLoading: payload.loading,
    };
  },
  [setSelectedAddressId.toString()]: (state, payload: SetSelectedAddressIdPayload) => {
    return {
      ...state,
      selectedAddressId: payload.id,
    };
  },
  [setLoyaltyPointsToRedeem.toString()]: (state, payload: SetLoyaltyPointsToRedeemPayload) => {
    return {
      ...state,
      loyaltyPointsToRedeem: payload.loyaltyPointsToRedeem,
      defaultLoyaltyPointsToRedeem: payload.loyaltyPointsToRedeem || state.defaultLoyaltyPointsToRedeem,
    };
  },
  [resetLoyaltyPointsToDefaultValue.toString()]: (state) => {
    return {
      ...state,
      loyaltyPointsToRedeem: state.defaultLoyaltyPointsToRedeem,
      defaultLoyaltyPointsToRedeem: undefined,
    };
  },
};

const reducer = createReducer<CheckoutState>(getInitialState(), handlers);

export default reducer;
